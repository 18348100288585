import Hamburger from "@/atoms/Hamburger";
import Image from "next/image";
import Link from "next/link";
import routes from "@/constants/routes";
import { LandingTheme } from "@/shared/types/theme";

export const LogoBar = ({
  theme,
  open,
  toggleMenu,
  className,
}: {
  theme: LandingTheme;
  open: boolean;
  toggleMenu: () => void;
  className?: string;
}): JSX.Element => (
  <div
    className={`relative z-50 flex items-center justify-between w-full md:px-6 px-5 lg:px-11 ${className}`}
  >
    <div className="flex items-start justify-start">
      <Link href={routes.home}>
        <a className={`relative xl:w-52 xl:h-12 h-8 w-24`}>
          <Image
            src={
              theme === "dark"
                ? "/koach/keyzy-klink-main-logo-light.svg"
                : "/koach/keyzy-klink-main-logo.svg"
            }
            alt="klink logo"
            layout="fill"
            priority
          />
        </a>
      </Link>
    </div>

    <Hamburger
      onClick={toggleMenu}
      open={open}
      className="relative z-50 xl:hidden"
      theme={theme}
    />
  </div>
);
