import React, { ReactElement } from "react";
import Image from "next/image";
import ModalPopup from "@/atoms/ModalPopup";
import { QRCodeModalProps } from "./types";
import getConfig from "next/config";
import { mobileConversionStore } from "@/components/shared/gtm";

const QRCodeModal = ({
  showModal,
  onClick,
}: QRCodeModalProps): ReactElement => {
  const { publicRuntimeConfig } = getConfig();

  const handleAppStoreDownloadClick = () => {
    mobileConversionStore({
      label: "apple-app-store",
      url: publicRuntimeConfig.NEXT_PUBLIC_APP_STORE_URL,
    });
  };

  const handlePlayStoreDownloadClick = () => {
    mobileConversionStore({
      label: "google-play-store",
      url: publicRuntimeConfig.NEXT_PUBLIC_PLAY_STORE_URL,
    });
  };

  return (
    <div>
      <ModalPopup name="QR Code" show={!!showModal} onOutsideClick={onClick}>
        <div className="flex justify-between gap-5 p-5">
          <div className="w-2/5 h-auto relative">
            <Image
              src="/koach/qr-code.svg"
              alt="QR code"
              id="qr_code"
              priority
              width={250}
              height={240}
            />
          </div>

          <div className="w-3/5 flex flex-col justify-center md:gap-8 relative">
            <div
              className="absolute top-0 right-0 cursor-pointer"
              onClick={onClick}
            >
              <Image
                src="/koach/icons/close.svg"
                alt="Close"
                priority
                width={28}
                height={28}
              />
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="text-xl md:text-2xl font-thin text-black text-left">
                Scan the QR code
              </h2>
              <p className="text-[14px] text-grey-500 font-thin text-left">
                Download the Klink app today and get started on your journey
                towards home ownership.
              </p>
            </div>

            <div className="flex flex-col md:flex-row justify-start items-center gap-6">
              <h3 className="text-black"> Get the app</h3>
              <div className="flex gap-4">
                <a 
                  href={publicRuntimeConfig.NEXT_PUBLIC_APP_STORE_URL}
                  onClick={() => handleAppStoreDownloadClick()}
                >
                  <Image
                    src="/koach/app-store.svg"
                    width="44"
                    height="44"
                    alt="App store"
                    className="cursor-pointer"
                  />
                </a>

                <a 
                  href={publicRuntimeConfig.NEXT_PUBLIC_PLAY_STORE_URL}
                  onClick={() => handlePlayStoreDownloadClick()}
                >
                  <Image
                    src="/koach/play-store.svg"
                    width="44"
                    height="44"
                    alt="Google play"
                    className="cursor-pointer"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </ModalPopup>
    </div>
  );
};

export default QRCodeModal;
