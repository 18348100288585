import React, { useEffect, useRef } from "react";
import { Props } from "./types";
import Image from "next/image";
import Button from "@/atoms/Button";
import RightArrow from "@/public/icons/arrow-right.svg";
import Close from "@/public/icons/close-simple-icon.svg";

const ModalPopup = (
  { name, show, className, title, text, icon, actionButtons, children, type, onOutsideClick }: Props,
  ref: React.Ref<HTMLInputElement> | undefined,
) => {
  const modalRef = useRef<any>(null);
  useEffect(() => {
    modalRef?.current?.scrollTo(0, 0);
    
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        if (show && onOutsideClick) {
          onOutsideClick();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [type, show, title, onOutsideClick]);

  return (
    <div
      className={`${name} w-full h-full bg-grey-800/80 ${className} z-[99] fixed inset-0 transition-opacity duration-300 ease-in-out ${
        show ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        ref={modalRef}
        className={`w-[90%] ${
          type === "landlords"
            ? "md:w-[643px] lg:w-[743px] pb-0 px-4 md:px-10"
            : type === "manageAccount"
            ? "md:w-[546px] pb-9 px-12"
            : type === "klink"
            ? "md:w-[360px] px-3 text-center md:rounded-2xl"
            : "md:w-[643px] rounded-3xl"
        } z-[60] h-max max-h-[calc(97%)] bg-white fixed translate-y-[-50%] inset-y-1/2 translate-x-[-50%] inset-x-1/2 overflow-y-scroll scrollBar`}
      >
        <div className={`flex flex-row items-center mx-2 md:mx-0 ${type === "klink" ? "justify-center" : ""}`}>
          {icon && <Image src={icon} width={64} height={64} alt={title} />}
          <div className={`text-black text-4xl font-bold ${icon && "ml-6"}`}>
            {title ? title : ""}
          </div>
        </div>
        <div className={`whitespace-pre-line mx-2 md:mx-0 ${type === "klink" ? "text-grey-300" : "text-black"}`}>
          {text ? text : ""}
        </div>
        {children}
        <div className={`flex flex-col md:flex-row ${name === "Close" ? "justify-center" : "justify-between"}`}>
          {actionButtons?.map((options, idx) => (
            <div key={idx.toString()}>
              <Button
                onClick={options.onClick}
                variant={options.variant === "white" ? "secondary" : "primary"}
                suffix={
                  options.showIcon ? (
                    options.name === "Close" ? (
                      <Close />
                    ) : (
                      <RightArrow className="md:ml-3" />
                    )
                  ) : (
                    ""
                  )
                }
              >
                {options.title}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(ModalPopup);
