export const routes = {
  home: "/",
  guides: "/guides",
  privacy: "/privacy",
  terms: "/terms",
  prizeCompetitionTerms: "/prize-competition-terms",
  prizeDrawTerms: "/prize-draw-terms",
  faqs: "/faqs",
  deleteAccount: "/delete-account",
  dataRemovalRequest: "/data-removal-request",
  howItWorks: "/how-it-works",
};
