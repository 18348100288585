import { FC } from "react";
import Image from "next/image";
import { twMerge } from "tailwind-merge";

import { Props } from "./types";

const variantClasses = {
  primary: "btn-primary",
  secondary: "btn-secondary",
  outline: "btn-outline",
  "outline-light": "btn-outline-light",
};

const Button: FC<Props> = ({
  children,
  className,
  variant = "primary",
  prefix = null,
  suffix = null,
  isLoading = false,
  disabled = false,
  ...props
}) => {
  let colorDependsOnVariant = variantClasses[
    variant as "primary" | "secondary" | "outline" | "outline-light"
  ] as string;

  const classes = twMerge(`
  h-[60px] mb-4 sm:mb-0 w-full sm:w-auto flex justify-center items-center px-6 py-5 text-lg font-bold text-left rounded leading-tight
  ${className ?? ""}
  ${colorDependsOnVariant ?? ""}
`);

  return (
    <button className={classes} disabled={isLoading || disabled} {...props}>
      <div className="flex items-center justify-center">
        {isLoading && (
          <div className="absolute flex">
            <Image src="/icons/throbber.gif" width={24} height={24} alt="throbber" />
          </div>
        )}
        <div className={`flex items-center ${isLoading && "invisible"}`}>
          <span className="flex items-center justify-center">
            {prefix && <span className="mr-3">{prefix}</span>}
            {children}
            {suffix && <span className="ml-3">{suffix}</span>}
          </span>
        </div>
      </div>
    </button>
  );
};

export default Button;
