import Link from "next/link";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Image from "next/image";

import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";
import { KlinkItems } from "../utils";

import { Props } from "../types";
import routes from "@/constants/routes";
import { useScrollBlock } from "@/shared/hooks/useScrollBlock";
import { LogoBar } from "./LogoBar";
import getConfig from "next/config";

const HeaderMobile = ({ theme }: Props): JSX.Element => {
  const { width } = useWindowSize();
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const { publicRuntimeConfig } = getConfig();

  const [activeDD, setIsActive] = useState("");

  const handleClick = (active: string | undefined) => {
    setIsActive(active === undefined || active === activeDD ? "" : active);
  };

  const toggleMenu = () => {
    setOpen((isMenuOpen) => !isMenuOpen);
  };

  useEffect(() => {
    if (width >= Screen.DESKTOP_1280) {
      setOpen(false);
      allowScroll();
    }

    if (width < Screen.DESKTOP_1280 && open) {
      blockScroll();
    }

    if (width < Screen.DESKTOP_1280 && !open) {
      allowScroll();
    }
  }, [width, open]);

  useEffect(() => {
    if (open) {
      allowScroll();
      setOpen(false);
    }
  }, [router]);

  useEffect(() => {
    if (!open) {
      return () => allowScroll();
    }
    return () => blockScroll();
  });

  return (
    <>
      <nav
        className={`z-50 w-full text-white py-4 overscroll-none overflow-hidden ${
          open ? "fixed inset-0" : ""
        } ${
          theme === "dark"
            ? "bg-navy"
            : theme === "light"
            ? "bg-white text-navy"
            : "bg-landing-ivory"
        }
       h-full`}
      >
        {open ? (
          <div
            className={`w-full relative h-full overflow-scroll flex flex-col ${
              theme === "dark"
                ? "bg-navy"
                : theme === "light"
                ? "bg-white"
                : "bg-landing-ivory"
            } overflow-hidden ${open ? "block" : ""}
            transition duration-300
            ${open ? "opacity-100 z-50 pointer-events-auto" : "opacity-0 -z-1 pointer-events-none"}
            `}
          >
            <LogoBar theme={theme} open={open} toggleMenu={toggleMenu} className="top-6" />

            <ul
              className={`flex relative xl:top-auto w-full h-full mb-10 mt-10 overflow-scroll px-5 md:px-20 flex-col text-center
        
      `}
            >
              {KlinkItems.map((item, idx) => (
                <li
                  className={`mb-7 md:mb-11 ${idx === 0 ? "mt-16" : ""}`}
                  key={idx}
                >
                  {(!item.items && (
                    <Link href={item.href}>
                      <a className="relative flex flex-col mx-auto w-fit" href={item.href}>
                        <span
                          className={`inline-block relative text-sm font-semibold pb-2 ${
                            theme === "dark" ? "text-white" : "text-navy"
                          }`}
                        >
                          {item.caption}
                        </span>
                        <span
                          className={`h-[1px] w-9/12 mt-1 absolute-center-x bottom-0  ${
                            router.pathname === item.href && "bg-landing-orange"
                          }`}
                        ></span>
                      </a>
                    </Link>
                  )) || (
                    <div
                      className={`group ${activeDD === item.caption ? "active" : ""} flex flex-col`}
                      onClick={() => handleClick(item.caption?.toString())}
                    >
                      <div className="relative flex flex-col mx-auto w-fit">
                        <span
                          className={`inline-block relative text-sm font-semibold pb-2 ${
                            theme === "dark" ? "text-white" : "text-navy"
                          }`}
                        >
                          {item.caption}
                          <span className="ml-2">
                            <Image
                              src="/budget-calculator/chevron-orange-bottom.svg"
                              width={11}
                              height={6}
                              alt="down chevron"
                              className={`transition-all duration-300 ease-in-out group-[.active]:rotate-180 rotate-0`}
                            />
                          </span>
                        </span>
                        <span
                          className={`h-[1px] w-full hidden group-[.active]:flex mt-1 absolute-center-x bottom-0 bg-landing-orange`}
                        ></span>
                      </div>
                      <div
                        style={{
                          maxHeight: activeDD === item.caption ? "800px" : "0px",
                        }}
                        className="overflow-hidden flex flex-col transition-all duration-500 ease-in-out group-[.active]:mt-7"
                      >
                        {item.items?.map((item, idx) => (
                          <Link href={item.href} key={item.href}>
                            <a
                              className="relative flex flex-col mx-auto w-fit last:mb-0 mb-7 md:mb-11"
                              href={item.href}
                            >
                              <span
                                className={`inline-block relative text-sm font-semibold pb-2 ${
                                  theme === "dark" ? "text-white" : "text-navy"
                                }`}
                              >
                                {item.caption}
                              </span>
                              <span
                                className={`h-[1px] w-9/12 mt-1 absolute-center-x bottom-0  ${
                                  router.pathname === item.href && "bg-landing-orange"
                                }`}
                              ></span>
                            </a>
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <LogoBar theme={theme} open={open} toggleMenu={toggleMenu} />
        )}
      </nav>
    </>
  );
};

export default HeaderMobile;
