import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { KlinkItems } from "../utils";
import { SubLayout } from "@/containers/layout/KoachLayout";
import { Props } from "../types";
import routes from "@/constants/routes";
import { useState } from "react";
import QRCodeModal from "@/components/koach/Shared/QRCodeModal";

const HeaderDesktop = ({ theme }: Props): JSX.Element => {
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);

  const handleSignUpClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowModal(true)
  };

  const itemClassess = (hoverable: boolean) =>
    `mr-9 mr-8 2xl:mr-12 px-2 pt-2 border-opacity-0 border-navy ${
      hoverable && "hover:border-opacity-100 border-landing-orange"
    } transition border-b duration-300`;

  return (
    <nav
      className={`relative z-50 w-full text-white py-8  ${
        theme === "dark"
          ? "bg-navy"
          : theme === "light"
          ? "bg-white"
          : "bg-landing-ivory"
      }`}
    >
      <QRCodeModal showModal={showModal} onClick={() => setShowModal(false)} />
      <SubLayout allowOverflow>
        <div className="relative z-50 flex items-center justify-between w-full h-16 mt-0 x-5">
          <div className="flex items-center justify-start gap-12">
            <Link href={routes.home}>
              <a className={`relative w-32 h-8 xl:w-52 xl:h-16`}>
                <Image
                  src={
                    theme === "dark"
                      ? "/koach/keyzy-klink-main-logo-light.svg"
                      : "/koach/keyzy-klink-main-logo.svg"
                  }
                  alt="klink logo"
                  layout="fill"
                  priority
                />
              </a>
            </Link>

            <ul className="relative flex flex-row items-center w-full z-10 xl:justify-end xl:flex-1 xl:flex-row">
              {KlinkItems?.map((item, idx) => (
                <li
                  className={`${itemClassess(router.pathname !== item.href)} 
                  ${
                    router.pathname === item.href &&
                    "border-b border-opacity-100 border-landing-orange"
                  }
                  `}
                  key={idx}
                >
                  {(!item.items && (
                    <Link href={item.href}>
                      <a
                        className="relative flex flex-col mx-auto w-fit"
                        href={item.href}
                      >
                        <span
                          className={`inline-block relative text-sm  3xl:text-base  font-semibold pb-2 ${
                            theme === "dark"
                              ? "text-white"
                              : "text-landing-blue"
                          }`}
                        >
                          {item.caption}
                        </span>
                        <span
                          className={`h-[1px] w-full transform scale-125 mt-1 absolute-center-x bottom-0 `}
                        ></span>
                      </a>
                    </Link>
                  )) || (
                    <div className="group/main relative cursor-pointer">
                      <div className="flex flex-col mx-auto w-fit">
                        <span
                          className={`inline-block relative text-sm  3xl:text-base  font-semibold pb-2 ${
                            theme === "dark"
                              ? "text-white"
                              : "text-landing-blue"
                          }`}
                        >
                          {item.caption}
                          <span className="ml-2">
                            <Image
                              src="/budget-calculator/chevron-orange-bottom.svg"
                              width={11}
                              height={8}
                              alt="down chevron"
                              className={`transition-all duration-300 ease-in-out group-hover/main:rotate-180 rotate-0`}
                            />
                          </span>
                        </span>
                        <span
                          className={
                            "h-[1px] w-full mt-1 absolute-center-x bottom-0"
                          }
                        ></span>
                      </div>
                      <div
                        className={`absolute -left-3 px-3 min-w-[115px] mt-px opacity-0 group-hover/main:flex cursor-pointer group-hover/main:opacity-100 pt-2 flex-col ${
                          theme === "dark"
                            ? "bg-landing-blue"
                            : theme === "light"
                            ? "bg-white"
                            : "bg-landing-ivory"
                        }`}
                      >
                        {item.items?.map((item, idx) => (
                          <Link href={item.href} key={idx}>
                            <a
                              className={`relative hidden opacity-0 group-hover/main:flex group-hover/main:animate-[rotateX_300ms_ease-in-out_forwards] flex flex-col w-fit mb-4 group/item first-of-type:pt-3`}
                              style={{ animationDelay: `${(idx + 1) * 60}ms` }}
                              href={item.href}
                              rel="noreferrer"
                              data-kk={idx}
                            >
                              <span
                                className={`inline-block relative text-sm  3xl:text-base  font-semibold pb-2 ${
                                  theme === "dark"
                                    ? "text-white"
                                    : "text-landing-blue"
                                }`}
                              >
                                {item.caption}
                              </span>
                              <span
                                className={`h-[1px] w-full group-hover/item:bg-landing-orange mt-1 absolute-center-x bottom-0  ${
                                  router.pathname === item.href &&
                                  "bg-landing-orange"
                                }`}
                              ></span>
                            </a>
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>

          {/* Right Side Buttons */}
          <div className="flex items-center">
            <a
              href="https://www.klink-app.com/download"
              onClick={(event) => handleSignUpClick(event)}
              className="px-8 py-2.5 text-center text-base text-white font-bold transition duration-300 ease-in-out transform rounded-3xl shadow bg-magenta hover:scale-105 cursor-pointer"
            >
              Download for free
            </a>
          </div>
        </div>
      </SubLayout>
    </nav>
  );
};

export default HeaderDesktop;
